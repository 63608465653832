import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "min-h-full flex flex-col"
};
const _hoisted_2 = {
  class: "relative min-w-0 p-7 flex flex-col flex-1 gl-container mx-auto"
};
import Header from '@/components/header';
import { onMounted, getCurrentInstance, ref, computed, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { getI18nLocal } from '@/i18n/i18n';
import Nav from '@/components/nav';
import { useRoute } from 'vue-router';
export default {
  __name: 'App',
  setup(__props) {
    const {
      locale
    } = useI18n({
      useScope: 'global'
    });
    const route = useRoute();
    const routeReload = ref(0);
    const store = useStore();
    const globalProperties = getCurrentInstance().appContext.config.globalProperties;
    const elLocale = computed(() => {
      return getI18nLocal(locale.value);
    });

    // 网络配置
    const getAllNetworks = () => {
      return store.dispatch('_config/queryAllNetworks');
    };

    // 币种配置
    const getAllSymbols = () => {
      return store.dispatch('_config/queryAllSymbols');
    };
    watchEffect(() => {
      var _store$state$_info, _store$state$_info$lo;
      if ((_store$state$_info = store.state._info) !== null && _store$state$_info !== void 0 && (_store$state$_info$lo = _store$state$_info.loginInfo) !== null && _store$state$_info$lo !== void 0 && _store$state$_info$lo.id) {
        // 组合列表
        Promise.all([getAllNetworks(), getAllSymbols()]).then(() => {
          const combinedList = store.getters['_config/getCombinedList'];
          store.commit('_config/Update_CombinedList', combinedList);
        });
      }
    });
    const isGuest = computed(() => {
      return route.name !== 'Login' && route.name !== 'ResetPassword' && route.name !== 'GoogleAuth';
    });
    onMounted(() => {
      // 调试用
      window['vm'] = globalProperties;
      window['store'] = store;

      // 登录用户信息
      store.dispatch('_info/queryLoginInfo');
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_el_config_provider = _resolveComponent("el-config-provider");
      return _openBlock(), _createBlock(_component_el_config_provider, {
        message: {
          max: 3
        },
        locale: _unref(elLocale)
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_unref(isGuest) ? (_openBlock(), _createBlock(_unref(Header), {
          key: 0
        })) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_2, [(_openBlock(), _createBlock(_component_router_view, {
          key: routeReload.value
        }, {
          default: _withCtx(({
            Component
          }) => [_unref(isGuest) ? (_openBlock(), _createBlock(_unref(Nav), {
            key: 0
          })) : _createCommentVNode("", true), (_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            key: 0
          })) : _createCommentVNode("", true)], 1024)), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            key: 1
          })) : _createCommentVNode("", true)]),
          _: 1
        }))])])]),
        _: 1
      }, 8, ["locale"]);
    };
  }
};